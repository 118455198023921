.l-f {
  background-color: $ultraGray;
  position: relative;
  z-index: 1;
  &__inner {
    position: relative;
    max-width: $contentWidth;
    width: 100%;
    height: 324.6px;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    @include mq-max(content) {
      padding-left: $contentPadding;
      padding-right: $contentPadding;
    }
    @include mq-max(sm) {
      flex-direction: column;
      align-items: center;
      height: auto;
      padding: 0 $contentPadding/2 105.5px;
    }
  }

  //会社情報
  &__info {
    width: 402px;
    height: 404.5px;
    background-color: $black-2;
    position: absolute;
    bottom: -40px; //copyrightの高さ
    left: 0;
    z-index: 2;
    color: $white;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.24);
    @include mq-max(content) {
      left: $contentPadding;
    }
    @include mq-max(md) {
      width: 350px;
      height: 380px;
    }
    @include mq-max(sm) {
      width: (426 / 556) * 100%;
      height: 404.5px;
      position: relative;
      top: -36px;
      left: 0;
      margin-bottom: 60px - 36px;
    }
    @include mq-max(xs) {
      width: 95%;
    }
    &-inner {
      width: 100%;
      height: 100%;
      padding: 10px 10px 36px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
    }
    &-logo {
      max-width: 248px;
      width: 100%;
      margin: 0 auto 40px;
      img {
        width: 100%;
        display: block;
        line-height: 1;
      }
      //英語サイト調整
      .eng & {
        margin-bottom: 60px;
        @include mq-max(md) {
          max-width: 200px;
        }
        @include mq-max(sm) {
          max-width: 248px;
        }
      }
    }
    &-address {
      font-size: 14px;
      text-align: center;
      line-height: 1.3;
    }
    &-contact {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      @include mq-max(md) {
        margin-bottom: 30px;
      }
      &>li {
        font-size: 14px;
        margin-right: 14px;
        &:last-child {
          margin-right: 0;
        }
      }
      //英語サイト調整
      .eng & {
        margin-bottom: 25px;
      }
    }
    &-btn {
      text-align: center;
      .c-btn {
        border-color: rgba($white , 0);
        background-color: $grayishWhite;
        color: $black-2;
        font-weight: 400;
        &::after {
          color: $black-2 !important;
          transition: all $transition;
        }
        &:hover {
          background-color: $keyColor;
          border-color: $keyColor;
          &::after {
            color: $white !important;
          }
        }
      }
    }
  }


  //フッターナビ
  &__nav {
    display: flex;
    padding-left: 402px + 81.5px;
    @include mq-max(md) {
      padding-left: 400px;
    }
    @include mq-max(sm) {
      padding-left: 0;
      width: 100%;
    }
  }
  &__links {
    max-width: 215px;
    width: auto;
    flex: 0 0 100%;
    margin-right: 80px;
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex-basis: auto;
    }
    @include mq-max(md) {
      margin-right: 40px;
      flex: 1 1 100%;
    }
    @include mq-max(sm) {
      max-width: 100%;
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
    &-item {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &>a {
        font-size: 15px;
        font-weight: 400;
        color: $black-3;
        display: block;
        position: relative;
        padding-right: 25px;
        &:after {
          content: '';
          display: inline-block;
          width: 6.5px;
          height: 11.8px;
          background: url('/inc/image/common/ico/ico_arrow-right.svg')no-repeat center / contain;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          transition: all $transition;
        }
        &:hover {
          color: $keyColor;
          &:after {
            right: 5px;
          }
        }
      }
    }
  }

  //小さめリンク
  &__subLink {
    margin-top: 10px;
    &__item {
      color: $white !important;
      font-size: 12px;
      display: inline-block;
      padding: 0 5px;
      font-weight: 400;
      &::after{
        content: none !important;
      }
    }
  }

  //Copyright
  &__copyright {
    font-size: 12px;
    text-align: center;
    display: block;
    background-color: $white;
    padding: 11px 10px;
    @include mq-max(content) {
      text-align: right;
    }
    @include mq-max(sm) {
      text-align: center;
    }
  }
}
