//タイムライン

// 専用変数設定
$width_time: 180px; //時間部分の横幅
$width_time-sm: 100px; //時間部分の横幅(sm)

.c-timeline {
  // &__list {}
  &__item {
    display: flex;
    margin-bottom: 40px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
      &:after {
        content: none;
      }
    }
    &:after {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background-color: $keyColor;
      position: absolute;
      top: 68px;
      left: calc(#{$width_time} / 2);
      @include mq-max(sm) {
        left: calc(#{$width_time-sm} / 2);
      }
    }
  }
  &__time {
    min-width: $width_time;
    position: relative;
    @include mq-max(sm) {
      max-width: $width_time-sm;
      min-width: 0;
      width: 100%;
    }
    &>span {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $keyColor;
      font-size: 18px;
      border: 3px solid $keyColor;
      border-radius: 50%;
      text-align: center;
      width: 68px;
      height: 68px;
      // line-height: 3.3;
      line-height: 1;
      margin: 0 auto;
      background-color: $white;
      &>span {
        display: inline-block;
        line-height: 1;
        position: relative;
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
          top: 2px;
        }
      }
    }
  }
  &__content {
    flex-grow: 1;
    @include mq-max(sm) {
      width: calc(100% - #{$width_time-sm});
    }
  }
  &__heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    @include mq-max(sm) {
      font-size: 18px;
    }
  }
}