//アコーディオン

.c-accordion {
  margin-bottom: 10px;
  transition: all $transition;
  background-color: rgba($white,.65);
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: $white;
    box-shadow: 0px 0px 20px rgba(0,0,0,.1);
  }

  &__title {
    padding: 19px 60px 19px 30px;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    transition: all $transition;
    cursor: pointer;
    @include mq-max(xs) {
      padding-right: 42px;
    }
    &:after {
      content: '';
      background: url('/inc/image/common/ico/ico_plus.png')no-repeat center / contain;
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 23px;
      transform: translateY(-50%);
      @include mq-max(xs) {
        width: 20px * .7;
        height: 20px * .7;
        right: 15px;
      }
    }
    &.open {
      font-size: 16px;
      font-weight: 600;
      color: $keyColor;
      &:after {
        background: url('/inc/image/common/ico/ico_minus.png')no-repeat center / contain;
      }
    }
  }
  &__body {
    font-size: 15px;
    padding: 13px 60px 20px 30px;
    color: #6E6E6E;
    display: none;
  }


  //Q&A
  &--faq {
    .c-accordion {
      &__title {
        padding-left: 81px;
        position: relative;
        @include mq-max(xs) {
          padding-left: 50px;
        }
        &:before {
          content: '';
          width: 26px;
          height: 27px;
          background: url('/inc/image/common/ico/ico_Q.svg')no-repeat center / contain;
          display: inline-block;
          position: absolute;
          top: 16px;
          left: 34px;
          transition: all $transition;
          @include mq-max(xs) {
            width: 26px * .7;
            height: 27px * .7;
            top: 20px;
            left: 20px;
          }
        }
        &.open {
          &:before {
            top: 19px;
          }
        }
      }

      &__body {
        padding-left: 81px;
        position: relative;
        @include mq-max(xs) {
          padding-left: 50px;
          padding-right: 20px;
        }
        &:before {
          color: #fff;
          background-color: $keyColor;
        }
      }
    }
  }


  //背景色
  &--bg-ultraGray {
    background-color: $ultraGray;
  }
}