.pageTop {
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 100;
  opacity: 1;
  transition: none !important;
  @include mq-max(sm) {
    bottom: 20px;
    right: 10px;
  }
  &::after {
    @include fa('f077');
    display: inline-block;
    color: $white;
    padding: 20px;
    background-color: $keyColor;
    border: 2px $keyColor solid;
    border-radius: 50%;
    line-height: 1;
    transition: all $transition;
  }

  &:hover {
    &::after {
      background-color: $grayishWhite;
      color: $keyColor;
    }
  }
}
