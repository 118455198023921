//工程パーツ
$flowContentWidth: 150px;

.c-process {
  @include mq-max(content) {
    overflow: hidden;
    overflow-x: scroll;
  }
  &__scrollBar {
    display: none;
    @include mq-max(content) {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 600;
      position: absolute;
      top: -50px;
      right: $contentPadding;
    }
    @include mq-max(sm) {
      right: $contentPadding/2;
    }
    &:before {
      content: '';
      display: inline-block;
      width: 50px;
      height: 2px;
      background: linear-gradient(to right,rgba(46, 79, 147, 0) 0%, $keyColor 100%);
      margin-right: 13px;
    }
  }
  &__inner {
    min-width: $contentWidthInner;
    padding-bottom: 35px;
  }
  //フロー名
  &__main {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    counter-reset: flow;
    position: relative;
    margin-bottom: 50px;
    &:before {
      content: '';
      display: inline-block;
      width: 100%;
      width: calc(100% - 150px);
      height: 2px;
      background: linear-gradient(to right,#cadfef 0%, #0076cc 100%);
      position: absolute;
      top: 27px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
    &-flow {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin: 0;
      width: $flowContentWidth;
      position: relative;
      &>span {
        display: block;
        &:before {
          counter-increment: flow;
          content: counter(flow,decimal-leading-zero);
          display: block;
          font-size: 20px;
          font-weight: 500;
          color: $white;
          line-height: 54px;
          background-color: #555555;
          width: 54px;
          height: 54px;
          margin: 0 auto 30px;
          border-radius: 50%;
          position: relative;
          z-index: 2;
        }
        &:after {
          content: '';
          width: 12px;
          height: 12px;
          display: inline-block;
          background-color: #555555;
          border-radius: 50%;
          position: absolute;
          bottom: -50px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  //期間
  &__term {
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    bottom: -12.5px - 60px;
    &:before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 1px;
      background-color: #707070;
      position: absolute;
      top: -9px;
      left: 0;
      z-index: -1;
    }
    &--first {
      width: calc(((100% - (#{$flowContentWidth} * 5)) / 4) * 1 + (#{$flowContentWidth} / 2 * 2));
      left: calc(#{$flowContentWidth} / 2);
    }
    &--second {
      width: calc(((100% - (#{$flowContentWidth} * 5)) / 4) * 2 + (#{$flowContentWidth} / 2 * 4));
      right: calc(#{$flowContentWidth} / 2);
    }

    // &-wrap {}
  }
}