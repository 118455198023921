.c-label {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.06em;
  line-height: 1.5;
  color: $white;
  background-color: $black-2;
  padding: 10px 18px;
}

.c-companyLabel {
  &__name {
    font-weight: 500;
    font-size: 14px;
    color: $white;
    padding: 10px 18px;
    background-color: $black-2;
    display: inline-block;
    margin: 0 0 30px;
  }
  &__info {
    font-size: 13px;
    border: 1px solid $lightGray;
    background-color: $white;
    border-radius: 6px;
    margin: 5px 5px 5px;
    padding: 5px 14px;
    display: inline-block;
    span {
      font-weight: 600;
      margin-left: 1em;
    }
  }
}