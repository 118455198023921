//CVエリア

.c-cvArea {
  border-top: 1px solid $bdrColor;

  //外堀
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 97px;
    @include mq-max(content) {
      padding-right: $contentPadding;
      padding-left: $contentPadding;
    }
    @include mq-max(sm) {
      padding-right: $contentPadding/2;
      padding-left: $contentPadding/2;
      padding-bottom: 90px;
    }
  }
  &__content {
    position: relative;
    max-width: 955px;
    width: 100%;
    margin: 0 auto;
    padding-top: 44px;
    z-index: 2;
    &:before {
      content: '';
      width: 48px;
      height: 4px;
      background-color: $keyColor;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      @include mq-max(sm) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  //見出し
  &__heading {
    font-size: 20px;
    margin-bottom: 55px;
    @include mq-max(sm) {
      text-align: center;
      margin-bottom: 50px;
    }
  }

  //内容
  &__body {
    display: flex;
    justify-content: space-between;
    @include mq-max(md) {
      margin: 0 -15px;
    }
    @include mq-max(sm) {
      flex-direction: column;
      align-items: center;
      margin: 0;
    }
  }
  &__txt {
    max-width: 423px;
    width: 100%;
    @include mq-max(md) {
      padding: 0 15px;
    }
    @include mq-max(sm) {
      margin-bottom: 28px;
      padding: 0;
    }
  }
  &__contact {
    max-width: 426px;
    width: 100%;
    @include mq-max(md) {
      padding: 0 15px;
    }
    @include mq-max(sm) {
      padding: 0;
    }

    //パーツ調整
    .c-btn {
      width: 100%;
      margin-bottom: 12px;
      font-size: 16px;
      padding-top: 22.5px;
      padding-bottom: 22.5px;
      padding-right: 55px;
      background-color: $keyColor;
      border: 2px $keyColor solid;
      &:after {
        right: 25px;
      }
      &:hover {
        background-color: $grayishWhite;
        color: $keyColor;
        &:after {
          right: 20px;
          background: url('/inc/image/common/ico/ico_arrow-right-keycolor.svg')no-repeat center / contain;
        }
      }

      //英語サイト
      .eng & {
        margin-bottom: 0;
      }
    }
  }
  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq-max(md) {
      flex-direction: column;
    }
    @include mq-max(sm) {
      flex-direction: row;
    }
    @include mq-max(xs) {
      flex-direction: column;
    }

    //英語サイト調整：PC版から要素を縦並びにする。
    .eng & {
      display: block;
    }

    &-item {
      font-size: 17px;
      margin-right: 17px;
      @include mq-max(md) {
        margin-right: 0;
      }
      @include mq-max(sm) {
        margin-right: 17px;
      }
      @include mq-max(xs) {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &>small {
        font-size: inherit;
      }
      &--tel {
        font-weight: 500;
        font-family: $helvetica;

        //英語サイト調整：見た目を調整
        .eng & {
          text-align: center;
          margin-right: 0;
        }
      }
      &--comment {
        font-size: 12px;
      }
    }

  }
}