//タイル

//共通設定
$tileShadow: 0px 0px 40px rgba(0,0,0,.1);
@mixin innerImg {
  display: block;
  line-height: 1;
  width: 70px;
  height: 80px;
  object-fit: contain;
  object-position: center;
  font-family: "object-fit: contain; object-position: center;";
  @include mq-max(xs) {
    width: 55px;
  }
}

//////////////////////////////////////////////////////////

.c-tile {
  @include mq-max(sm) {
    display: flex;
    align-items: center;
  }
  @include mq-max(xs) {
    align-items: flex-start;
  }

  //レイアウト
  &__wrap {
    display: flex;
    justify-content: center;
    max-width: 900px;
    margin: 0 auto 30px;
    @include mq-max(sm) {
      flex-direction: column;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item {
    max-width: 260px;
    width: 100%;
    margin-right: 60px;
    @include mq-max(md) {
      margin-right: 25px;
    }
    @include mq-max(sm) {
      max-width: 100%;
      margin: 0 0 60px 0;
    }
    &:last-child {
      margin-right: 0;
      @include mq-max(sm) {
        margin-bottom: 0;
      }
    }
  }

  //構成パーツ
  &__img {
    width: 198px;
    height: 198px;
    background-color: $white;
    box-shadow: $tileShadow;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 30px;
    @include mq-max(sm) {
      flex-shrink: 0;
      margin: 0 40px 0 0;
    }
    @include mq-max(xs) {
      width: 130px;
      height: 130px;
      margin-right: 30px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &>img {
      @include innerImg;
    }
  }
  &__body {
    @include mq-max(sm) {
      flex-grow: 1;
    }
  }
  &__title {
    font-size: 17px;
    text-align: center;
    margin-bottom: 25px;
    @include mq-max(sm) {
      text-align: left;
    }
    @include mq-max(xs) {
      margin-bottom: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__txt {
    font-size: 14px;
    letter-spacing: 0.03em;
    line-height: (26 / 14);
  }
}


//アンカーリンクタイル
.c-anchorTile {
  background-color: $white;
  box-shadow: 0px 0px 10px rgba(0,0,0,.1);
  padding: 50px 10px 55px;
  position: relative;
  width: 198px;
  display: block;
  @include mq-max(md) {
    width: 150px;
    padding: 20px 10px 35px;
  }
  @include mq-max(sm) {
    margin: 0 auto;
  }
  &:after {
    @include fa('f078');
    color: $textColor;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1;
    transition: all $transition;
    @include mq-max(md) {
      bottom: 15px;
    }
  }
  &:hover {
    box-shadow: $tileShadow;
    &:after {
      bottom: 20px;
      @include mq-max(md) {
        bottom: 10px;
      }
    }
  }

  &__inner {
    &>img {
      @include innerImg;
      margin: 0 auto;
    }
  }
  &__txt {
    color: $textColor;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.5;
    margin-top: 35px;
    text-align: center;
    @include mq-max(md) {
      font-size: 14px;
      margin-top: 20px;
    }
  }

  //レイアウト
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -20px;
    @include mq-max(sm) {
      margin: 0 -5px -20px;
    }
    &-item {
      padding: 0 20px;
      @include mq-max(sm) {
        width: 50%;
        padding: 0 5px 20px;
      }
    }
  }
}