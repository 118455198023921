//リンク系パーツ

//より詳しく
.c-moreLink {
  font-size: 14px;
  font-weight: 500;
  color: $black-3 !important;
  text-align: center;
  display: inline-block;
  transition: all $transition;
  letter-spacing: -0.02em;
  &:hover {
    color: $keyColor !important;
    .c-moreLink {
      &__arrow {
        background-color: $keyColor;
      }
    }
  }

  &__arrow {
    width: 44px;
    height: 44px;
    display: block;
    background-color: $black-2;
    border-radius: 50%;
    color: $white;
    position: relative;
    margin: 0 auto 10px;
    transition: all $transition;
    &>i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      line-height: 1;
    }
  }
}