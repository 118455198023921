//サイトレイアウト構造を記述

#container {}
#main {}
#contents {
  padding: 0;
  @include mq-max(sm) {
    width: $full-size;
    padding: 0;
  }
}

//gridlex調整
[class*="grid"] {
  margin-bottom: -1 * $contentPadding; //gridlexを使うことでできてしまう下余白を相殺。
}
[class*="col-"] {
  img {
    width: 100%;
    display: block;
  }
}
[class*="col-1"],
[class*="col-2"],
[class*="col-3"],
[class*="col-4"] {
  p {
    line-height: 1.7;
    @include mq-max(xs) {
      line-height: 1.8;
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: $sectionPadding 0;
  @include mq-max(content) {
    padding: 0 $contentPadding $sectionPadding;
  }
  @include mq-max(sm) {
    padding: 0 $contentPadding/2 $sectionPadding;//お好みで変更
  }
  &__inner {
    padding: 0 0 $sectionInnerPadding;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }
    //padding-bottom大き目
    &--mbLarge {
      padding: 0 0 80px;
    }
  }
  //40pxの余白（基本l-section__innerの中で使用）
  &__content {
    padding-bottom: 40px;
    &:last-child {
      padding-bottom: 0;
    }
    //padding-bottom大き目
    &--mbLarge {
      padding: 0 0 50px;
    }
  }

  &__head {
    position: relative;
    padding: 80px $contentPadding 95px;
    margin-bottom: $sectionPadding;
    background-color: $ultraGray;
    @include mq-max(sm) {
      padding: 80px $contentPadding/2 95px;
    }

    // &-inner {}

    //アイコン
    &--img {
      margin-bottom: calc(#{$sectionPadding} + 170px);
      @include mq-max(xs) {
        margin-bottom: calc(#{$sectionPadding} + 100px);
      }
      //c-tile__imgと連携
      .c-tile__img {
        position: absolute;
        bottom: -170px;
        left: 50%;
        transform: translateX(-50%);
        @include mq-max(xs) {
          bottom: -100px;
        }
      }
    }
    //素材
    &--material {
      margin-bottom: calc(#{$sectionPadding} + 65px);
      @include mq-max(xs) {
        margin-bottom: calc(#{$sectionPadding} + 40px);
      }
      .c-materialCard__thumb {
        top: auto;
        bottom: -65px;
        margin: 0;
      }
    }
    //お問い合わせステップ図
    &--contactflow {
      margin-bottom: calc(#{$sectionPadding} + 115px);
      @include mq-max(xs) {
        margin-bottom: calc(#{$sectionPadding} + 75px);
      }
    }
    //サンプルテストフロー
    &--sample-testflow {
      margin-bottom: 0;
    }
  }

  //padding調整
  //下層見出しすぐ下用
  &--largeTop {
    padding-top: 90px;
    @include mq-max(content) {
      padding-top: $sectionPadding;
    }
  }
  //下層見出し一番下のl-sectionに
  &--largeBottom {
    padding-bottom: 120px;
    @include mq-max(content) {
      padding-bottom: 90px;
    }
  }
  &--noTop {
    padding-top: 0;
  }
  &--noBottom {
    padding-bottom: 0;
  }

  //横幅調整用
  &--full {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    .l-section__inner {
      @include mq-max(content) {
        padding-left: $contentPadding;
        padding-right: $contentPadding;
      }
      @include mq-max(sm) {
        padding-left: $contentPadding/2;
        padding-right: $contentPadding/2;
      }
    }
  }
  &--medium {
    max-width: 800px;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }
  &--small {
    max-width: 600px;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }

  //grid調整(デフォで付く下余白をマージンで相殺)
  // [class*="grid"] {
  //   margin-bottom: -1 * $contentPadding;
  // }
}


//max-width(l-sectionやl-section__innerと同じ場所では使わない。内部で使う)
.l-mw {
  margin: 0 auto;
  width: 100%;
  &--contentWidth {
    max-width: $contentWidth;
  }
  &--contentWidthInner {
    max-width: $contentWidthInner;
  }
  &--576 {
    max-width: 576px;
  }
  &--800 {
    max-width: 800px;
  }
  &--900 {
    max-width: 900px;
  }
  &--960 {
    max-width: 960px;
  }
}


//z-index付与 基本2で他のパターンを作る場合はModifierで
.l-zIndex {
  position: relative;
  z-index: 2;
}


//TOPで使う用の新しいコンテナクラス
.l-container {
  &__inner {
    max-width: $contentWidthInner;
    margin: 0 auto;
    &--wide {
      max-width: $contentWidth;
    }
    &--pad {
      @include mq-max(content) {
        padding-left: $contentPadding;
        padding-right: $contentPadding;
      }
      @include mq-max(sm) {
        padding-left: $contentPadding/2;
        padding-right: $contentPadding/2;
      }
    }
  }

  &__wideImg {
    width: calc(100% - 100px);
    @include mq-max(md) {
      width: 100%;
    }
    img {
      object-fit: cover;
      object-position: right center;
      font-family: "object-fit: cover; object-position: right center;";
      width: 100%;
      height: 470px;
      @include mq-max(iPadPro-v) {
        height: 445px;
        object-position: center;
      }
      @include mq-max(xs) {
        height: 174px;
      }

    }
  }
}


//大きい文字背景 (mixinに共通設定を記載)
.l-bgWord {
  position: relative;
  &:before {
    left: -50px - 115px;
    @include mq-max(xlg) {
      left: -50px - 15px;
    }
    @include mq-max(bp1100) {
      left: -15px;
    }
  }

  //About
  &--about {
    &:before {
      @include l-bgWord('About');
    }
  }

  //Benefits
  &--benefits {
    &:before {
      @include l-bgWord('Benefits');
    }
  }

  //Service
  &--service {
    &:before {
      @include l-bgWord('Service');
      top: -92px;
    }
  }

  //Products
  &--classifying {
    &:before {
      @include l-bgWord('Products');
      top: -25px;
    }
  }

  //Industries
  &--field {
    &:before {
      @include l-bgWord('Industries');
      color: $white;
      top: -110px;
      left: auto;
      right: -50px - 115px;
      @include mq-max(xlg) {
        right: -50px - 15px;
      }
      @include mq-max(bp1100) {
        right: 0;
      }
    }
  }

  //Cases
  &--cases {
    &:before {
      @include l-bgWord('Cases');
      top: -115px;
      color: $white;
    }
  }

  //Steps
  &--flow {
    &:before {
      @include l-bgWord('Steps');
    }
  }

  //FAQ
  &--qa {
    &:before {
      @include l-bgWord('FAQ');
      top: -115px;
      color: $white;
    }
  }

  //News
  &--news {
    &:before {
      @include l-bgWord('News');
      top: -125px;
    }
  }
}


//重ねレイアウト
.l-overlay {
  display: flex;
  align-items: flex-start;
  @include mq-max(iPadPro-v) {
    flex-direction: column;
  }
  &__desc {
    width: 670px;
    flex-shrink: 0;
    background-color: $ultraGray;
    padding: 89px 214px 89px 0;
    position: relative;
    @include mq-max(content) {
      padding-left: $contentPadding;
    }
    @include mq-max(md) {
      flex-shrink: .2;
      width: 70%;
      padding-right: 150px;
    }
    @include mq-max(iPadPro-v) {
      width: 100%;
      padding: 0 $contentPadding 30px;
    }
    @include mq-max(sm) {
      padding: 0 $contentPadding/2 30px;
    }
    &:before {
      content: '';
      display: inline-block;
      background-color: $ultraGray;
      width: calc((100vw - #{$contentWidthInner}) / 2);
      height: 100%;
      position: absolute;
      top: 0;
      left: calc((100vw - #{$contentWidthInner}) / 2 * -1);
      @include mq-max(bp1100) {
        content: none;
      }
    }
  }
  &__img {
    width: 695px;
    flex-shrink: 0;
    position: relative;
    top: -125px;
    left: -115px;
    @include mq-max(content) {
      flex-shrink: .2;
      width: 50%;
      top: -50px;
    }
    @include mq-max(iPadPro-v) {
      width: 100%;
      top: 0;
      left: 0;
    }
    img {
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16);
      @include mq-max(iPadPro-v) {
        box-shadow: none;
      }
    }

    &-btn {
      display: block;
      transition: all $transition;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(4.171879768371582px);
        background-color: rgba(0,0,0,.3);
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity $transition;
      }
      &:hover {
        &:before {
          opacity: 1;
        }

        .l-overlay {
          &__img {
            //写真の上に置く文字
            &-aboveTxt {
              top: 50%;
              opacity: 1;
            }
          }
        }
      }
    }

    //写真の上に置く文字
    &-aboveTxt {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      opacity: 0;
      transition: all $transition;
      &-thumb {
        width: 74px;
        height: 74px;
        margin: 0 auto 10px;
        border: 2px solid $white;
        border-radius: 50%;
        position: relative;
        &>img {
          display: inline-block;
          width: auto;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &-txt {
        font-size: 15px;
        font-weight: 500;
        color: $white;
        letter-spacing: 0.06em;
        text-align: center;
      }
    }
  }
}


//background共通設定
@mixin bg_scss() {
  @include mq-max(content) {
    padding-top: $sectionPadding;
    margin: 0 0 $sectionPadding;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

//背景色（.l-sectionを囲って使う）
.l-bg {
  @include bg_scss();
  &--keyColor {
    background-color: $keyColor;
  }
}

//背景ドット
.l-bgDots {
  padding: 90px $contentPadding;
  background: url('/inc/image/common/bg/bg_dots.png');
  overflow: hidden;
  @include mq-max(sm) {
    padding: 60px $contentPadding/2;
  }
  &--largeBottom {
    padding-bottom: 120px;
    @include mq-max(sm) {
      padding-bottom: 90px;
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
$breakpoint-value : xs,sm,md,lg;

.u-taL {
  text-align: left !important;
}
.u-taC {
  text-align: center !important;
  //「$breakpoint-value」で設定したブレイクポイント配列でクラスを作成。
  //例：.u-taC--xs-taL
  @each $breakpoint in $breakpoint-value {
    &--#{$breakpoint} {
      &-taL {
        @include mq-max(#{$breakpoint}) {
          text-align: left !important; //leftに切り替え
        }
      }
    }
  }
}
.u-taR {
  text-align: right !important;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.u-only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

.u-not-under-xs {
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-not-under-sm {
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-not-under-md {
  @include mq-max(md) {
    display: none !important;
  }
}
.u-not-under-bp1100 {
  @include mq-max(bp1100) {
    display: none !important;
  }
}


.u-under-bp1100 {
  display: none;
  @include mq-max(bp1100) {
    display: block !important;
  }
}

.u-under-md {
  display: none;
  @include mq-max(md) {
    display: block !important;
  }
}

.u-under-sm {
  display: none;
  @include mq-max(sm) {
    display: block !important;
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ内アンカー位置調整

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-anchorFix {
  padding-top: $headerHeight;
  margin-top: -1 * $headerHeight;
  @include mq-max(content) {
    padding-top: $headerHeight-sp;
    margin-top: -1 * $headerHeight-sp;
  }
  //l-section__inner用
  // &__inner {}
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//互い違い
.l-alternate {
  &__wrap {
    margin-bottom: 90px;
    @include mq-max(xs) {
      margin-bottom: 60px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__img {
    @include mq-max(xs) {
      margin-bottom: -15px;
    }
  }
  &__desc {}
}
