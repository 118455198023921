.u- {
  &bdr {
    &--keyColor {
      background: linear-gradient(transparent 60%, $keyColor 60%);
    }
    &--yellow {
      background: linear-gradient(transparent 60%, #FFEF69 60%);
    }
  }

  &txt {
    &--red {
      color: $red;
    }
    &--middle {
      font-size: 17px;
      font-weight: 600;
    }
    &--largest {
      line-height: 3;
      font-size: 120%;
    }
  }
}