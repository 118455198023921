///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl {
  //キャッチコピー
  &-main {
    font-size: 26px;
    margin-bottom: 32px;
    @include mq-max(sm) {
      font-size: 20px;
    }
  }

  //中見出し
  &-middle {
    font-size: 17px;
    margin-bottom: 30px;
    &--mbShort {
      margin-bottom: 20px;
    }
  }

  //小見出し
  &-small {
    font-size: 14px;
    margin-bottom: 10px;
  }


  //ライン付き見出し
  &-line {
    font-size: 20px;
    font-weight: 600;
    padding-top: 25px;
    margin-bottom: 110px;
    position: relative;
    @include mq-max(sm) {
      margin-bottom: 90px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: '';
      width: 48px;
      height: 4px;
      background-color: $keyColor;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
    &--center {
      text-align: center;
      &:before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &--lowBottom {
      margin-bottom: 32px;
    }
    &--noBottom {
      margin-bottom: 0;
    }
  }

  //大見出し
  &-lg {
    font-size: 30px;
    margin-bottom: 80px;
    line-height: (50 / 30);
    @include mq-max(iPadPro-v) {
      margin-bottom: 40px;
    }
    @include mq-max(sm) {
      font-size: 4.2vw;
    }
    @include mq-max(xs) {
      font-size: 5.3vw;
    }
  }


  //Modifier
  &--center {
    text-align: center;
    &--xs-left {
      @include mq-max(xs) {
        text-align: left;
      }
    }
    &--sm-left {
      @include mq-max(sm) {
        text-align: left;
      }
    }
  }

}


//サブタイトル
.c-subTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  &--center {
    text-align: center;
  }

  //英語のみ文字サイズを小さくする
  .eng & {
    font-size: 18px;
  }
}

//動画紹介タイトル
.c-movieTitle {
  font-size: 20px;
  font-weight: 400;
  padding-top: 8px;
  margin-bottom: 0;
  @include mq-max(md) {
    font-size: 17px;
  }
  @include mq-max(sm) {
    font-size: 20px;
  }
  @include mq-max(xs) {
    font-size: 17px;
  }
}

//お知らせタイトル
.c-newsTitle {
  small {
    display: block;
    font-size: 16px;
    @include mq-max(sm) {
      font-size: 14px;
    }
  }
  font-size: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid $keyColor;
  margin-bottom: 20px;
  @include mq-max(sm) {
    font-size: 24px;
  }
}