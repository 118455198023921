///////////////////////////////////////////////////////////////////////////////////////////////////////

//お知らせ一覧

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-newslist {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }

  dl {
    width: 100%;
    border-bottom: 1px solid $gray;
    display: flex;
    align-items: center;
    @include mq-max(sm) {
      flex-direction: column;
    }
    dt,dd {
      color: $black-3;
      font-size: 15px;
      font-weight: 400;
      display: inline-block;
      padding: 18px 22px;
      @include mq-max(sm) {
        width: 100%;
        padding: 18px 10px;
      }
    }
    dt {
      width: 12%;
      padding-right: 12px;
      @include mq-max(md) {
        width: 15%;
      }
      @include mq-max(sm) {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 2px;
      }
    }
    dd {
      position: relative;
      line-height: 1.5em;
      padding-left: 12px;
      width: calc(100% - 12% - 22px);
      @include mq-max(md) {
        padding-left: 10px;
      }
      @include mq-max(sm) {
        width: 100%;
        padding-right: 30px;
        padding-top: 0;
        padding-bottom: 15px;
      }
    }
  }

  a {
    dd::after {
      @include fa('f054');
      color: $black-2;
      margin-left: 10px;
      -webkit-font-smoothing:antialiased;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      @include mq-max(sm) {
        right: 10px;
        top: 40%;
      }
    }
    &[target="_blank"]{
      dd::after{
        @include fa('f35d');
      }
    }
    &[href$=".pdf"] {
      dd::after {
        @include fa('f1c1');
        font-weight: 400;
        color: $red;
      }
    }
    &[href="javascript:void(0);"] {
      dd {
        &::after {
          content: none;
        }
      }
    }
  }


  &__item {
    &>a {
      display: flex;
      align-items: baseline;
      transition: all $transition;
      &:after {
        content: none;
      }
      &:hover {
        background-color: $ultraGray;
      }
    }
  }
  &__date {
    display: inline-block;
    font-size: 15px;
    font-weight: 100;
    color: $textColor;
  }
}