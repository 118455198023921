.l-header {
  background-color: $white;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
  @include mq-max($changePoint) {
    box-shadow: none;
  }
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: $headerHeight;
    margin: 0 auto;
    padding: 0 0 0 30px;
    position: relative;
    z-index: 100;
    @include mq-max(content) {
      padding: 0 0 0 15px;
    }
    @include mq-max($changePoint) {
      display: block;
      height: $headerHeight-sp;
      padding: 0;
      position: fixed;
      top: 0;
      background: transparent;
    }
  }

  h1,.logo {
    margin-bottom: 0;
    margin-right: 20px;
    line-height: 0;
    display: inline-block;
    width: 320px; // 状況によって変更可
    @include mq-max(bp1350) {
      width: 240px;
    }
    // @include mq-max(lg) {
    //   width: 240px;
    // }
    @include mq-max(content) {
      width: 180px; // 状況によって変更可
    }
    @include mq-max($changePoint) {
      margin: 0;
      width: 323px;
    }
    @include mq-max(xs) {
      width: 220px;
    }
    img {
      width: 100%;
    }
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: .7;
      }
    }
  }

  &__modules {
    position: relative;
    line-height: 0;
    @include mq-max($changePoint) {
      height: 100%;
      padding: 10px 20px;
      background-color: $white;
      line-height: 0;
      display: flex;
      align-items: center;
      box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
    }
  }
}


//スマホNavi展開時のフィルター
.l-filter {
  display: none;
  pointer-events: none;
  &--show {
    @include mq-max($changePoint) {
      pointer-events: auto;
      display: block;
      background-color: $black;
      opacity: .5;
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 109;
    }
  }
}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  @include mq-max($changePoint) {
    display: block;
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
    width: 30px;
    height: 20px;
    cursor: pointer;
    z-index: 111;
    span {
      display: block;
      position: absolute;
      width: 30px;
      border-bottom: solid 2px $black-2;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 0;
      z-index: 2;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2) {
        top: 10px;
      }
      &:nth-child(3) {
        top: 20px;
      }
    }
    &.is-toggleActive {
      span{
        &:nth-child(1) {
          top: 10px;
          left: 0px;
          transform: rotate(-45deg);
        }
        &:nth-child(2),&:nth-child(3) {
          top: 10px;
          transform: rotate(45deg);
        }
      }

      &::after {
        content: '';
        display: block;
        width: 70px;
        height: $headerHeight-sp;
        position: absolute;
        top: -29px;
        right: -19px;
        background-color: $white;
        z-index: 1;
      }
    }
  }
}
