///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-btn {
  position: relative;
  display: inline-block;
  font-size: 15px;
  padding: 17px 66px 17px 33px;
  width: auto;
  color: $white;
  background-color: $black-2;
  line-height: 1.25;
  letter-spacing: 0.06em;
  border: 1px solid $black-2;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
  transition: color $transition,
              background-color $transition;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: $white;
    background-color: $black;
    border-color: $black;
    &:after {
      right: 26px;
    }
  }
  &:after {
    // @include fa('f054');
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 33px;
    transform: translateY(-50%);
    transition: right $transition;
  }

  //通常ボタン
  &:not([href$=".pdf"]):not([target="_blank"]) {
    &:after {
      width: 8.5px;
      height: 13.8px;
      background: url('/inc/image/common/ico/ico_arrow-right-white.svg')no-repeat center / contain;
    }
  }

  //PDF
  &[href$=".pdf"] {
    &:hover {
      &:after {
        right: 33px;
        color: $white;
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &:after {
      color: $white;
    }
    &:hover {
      &:after {
        right: 33px;
        color: $white;
      }
    }
  }

  //長いボタン
  &--long {
    width: 426px;
    padding: 17px 66px 17px 66px;
    @include mq-max(xs) {
      width: 100%;
    }
  }

  //小さいボタン
  &--small {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  //アイコン動画
  &--movie {
    &:after {
      background: url('/inc/image/common/ico/ico_movie-white.svg')no-repeat center / contain !important;
      width: 20px !important;
      height: 18px !important;
    }
    &:hover {
      &:after {
        right: 33px !important;
      }
    }
  }
}