//モーダル

//mixin
@mixin modalFullWideImg {
  picture {
    width: 100%;
    height: 100%;
    padding: 15px;
    img {
      display: block;
      line-height: 1;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      font-family: "object-fit: contain; object-position: center;";
    }
  }
}

////////////////////////////////////////////////////////////////////////

.c-modal {
  //全体を囲うクラス。JS側で指定して追加
  &__wrap {
    //パーツ調整
    .mfp-iframe-holder {
      .mfp-content {
        max-width: 1034px;
      }
    }
  }

  //閉じるボタン
  &__close {
    pointer-events: auto;
    width: 60px;
    height: 60px;
    background-color: rgba(0,0,0,.4);
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1046;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq-max(sm) {
      top: 10px;
      right: 15px;
    }
    &::before {
      content: '';
      display: inline-block;
      width: 36px;
      height: 44px;
      background: url('/inc/image/common/modal/ico_close.svg')no-repeat center / contain;
    }
  }

  //白背景
  &__whiteBox {
    width: 100%;
    padding: 60px;
    background-color: $white;
    @include mq-max(sm) {
      padding: 80px 15px 30px;
    }
    &--p0 {
      padding: 0;
    }
  }

  //白背景内のレイアウト
  &__2colmun {
    display: flex;
    margin: 0 -20px;
    @include mq-max(sm) {
      margin: 0 -15px;
      flex-direction: column;
    }
    &-item {
      padding: 0 20px;
      width: 100%;
      @include mq-max(sm) {
        padding: 0 15px;
      }
    }

    &--middle {
      align-items: center;
    }
  }

  //商品写真
  &__rect {
    width: 462px;
    height: 462px;
    background-color: rgba($lightGray,.25);
    @include modalFullWideImg;
    @include mq-max(md) {
      width: 300px;
      height: 300px;
    }
    @include mq-max(sm) {
      margin-bottom: 30px;
      width: 300px;
      height: 300px;
    }
  }

  //構成要素
  &__tag {
    margin-bottom: 15px;
  }
  &__name {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
    @include mq-max(sm) {
      margin-bottom: 10px;
    }
  }
  &__model {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
    display: block;
  }

  //トリガー
  &__btn {
    &--movie {}
  }
}


//モーダルリスト
.c-modalList {
  display: block;
  text-align: center;
  &__rect {
    display: block;
    width: 180px;
    height: 180px;
    box-shadow: 0px 0px 20px rgba(0,0,0,.1);
    margin: 0 auto;
    transition: all $transition;
    position: relative;
    @include mq-max(md) {
      width: 150px;
      height: 150px;
    }
    @include modalFullWideImg;
    //虫眼鏡
    &::after {
      @include fa('f002');
      line-height: 1;
      position: absolute;
      top: 6px;
      right: 6px;
      width: 33px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $lightGray;
      color: $black-2;
      border-radius: 50%;
      pointer-events: none;
      transition: all $transition;
      @include mq-max(md) {
        font-size: 12px;
        width: 25px;
        height: 25px;
      }
    }
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    color: $textColor;
    line-height: 1.5;
    text-align: center;
    margin-top: 30px;
    @include mq-max(sm) {
      margin-top: 10px;
    }
  }
  &__sub {
    font-size: 11px;
    font-weight: 400;
    color: $textColor;
    line-height: 1.5;
    margin-top: 5px;

    //囲み線の設定
    display: inline-block;
    color: $textColor !important;
    padding: 4px 14px;
    background-color: $white;
    border: 1px solid $bdrColor;
    border-radius: 6px;
    transition: all $transition;
  }

  //ホバー
  &:hover {
    .c-modalList {
      &__rect {
        box-shadow: 0px 0px 40px rgba(0,0,0,.15);
        background-color: $white;
        &::after {
          background-color: $keyColor;
          color: $white;
        }
      }
    }
  }


  //レイアウト
  &__list {
    justify-content: center;
    @include mq-max(sm) {
      justify-content: flex-start;
    }
  }
  // &__item {}
  &__modal {
    max-width: 1034px;
    width: 100%;
    margin: 0 auto;
  }
}