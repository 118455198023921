//変数定義

/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/

//----------------------------------------------------------------
//    レイアウト設定
//----------------------------------------------------------------
$contentWidth    : 1200px;// 全体のコンテンツ幅
$contentWidthInner: 1100px;// 全体のコンテンツ幅
$contentPadding  : 30px;//パディング:gridlexでも使用
$sectionPadding  : 60px; //.l-sectionの上下padding
$sectionInnerPadding : 50px; //.l-section__innerのpadding
$tablet-break    : 768px;//タブレットでのブレイクポイント
$mobile-break    : 568px;//モバイルでのブレイクポイント
$padding-break   : 40px;//基本のコンテンツ余白
$padding-content : ($padding-break / 2);//テーブルの上下などの余白指定

//----------------------------------------------------------------
//    header fixed設定
//----------------------------------------------------------------
$is-fixed        : true;
$headerHeight    : 90px;
$headerHeight-sp : 75px; //ここを変えるとmenu.jsの「headerHeight」の値も自動的に同じ数字に代わる。
$changePoint     : md; //ハンバーガーメニューに切り替わるポイント


/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/

//全体をレスポンシブ非対応にする時は false
$responsiveMode : true;


// max-width(gridlexに合わせる)
$breakpoint-max: (
  'bp480': 'screen and (max-width: 480px)',
  'xs': 'screen and (max-width: 568px)', //スマホ境界
  'bp700': 'screen and (max-width: 700px)',
  'sm': 'screen and (max-width: 768px)', //タブレット境界
  'iPadPro-v': 'screen and (max-width: 834px)', //iPad Pro(10.5~11)　縦
  'bp900': 'screen and (max-width: 900px)',
  'md': 'screen and (max-width: 1024px)',
  'bp1100': 'screen and (max-width: 1100px)',
  'content': 'screen and (max-width:'$contentWidth + $contentPadding')',
  'lg': 'screen and (max-width: 1280px)',
  'bp1350': 'screen and (max-width: 1350px)',
  'xlg': 'screen and (max-width: 1430px)',
) !default;

// min-width(gridlexに合わせる)
$breakpoint-min: (
  'xs': 'screen and (min-width: 568px)', //スマホ境界
  'sm': 'screen and (min-width: 768px)', //タブレット境界
  'iPadPro-v': 'screen and (min-width: 834px)', //iPad Pro(10.5~11)　縦
  'md': 'screen and (min-width: 1024px)',
  'content': 'screen and (min-width:'$contentWidth + $contentPadding')',
  'lg': 'screen and (min-width: 1280px)',
) !default;


/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/

$white           : #FFFFFF;
$grayishWhite    : #fcfcfc;//全ページ共通背景色
$gray            : #CCCCCC;
$black           : #000000;
$gray-l          : lighten( $gray, 15%);
$red             : #c82e2e;

$keyColor        : #2E4F93; //キーカラー
$keyColor-dark   : darken($keyColor,10);
$accentColor     : $red; //アクセントカラー

//追加分
$black-1         : #4D4D4D;
$black-2         : #464646;
$black-3         : #262626;
$ultraGray       : #f4f4f4;
$lightGray       : #e0e0e0;

$bdrColor        : $lightGray;

/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/

$FontAwesome: 'Font Awesome 5 Free';

$textColor: $black-2;
$textColorLight: lighten($textColor,10);
$textColorDark: darken($textColor,10);
$lineHeight: 1.5; // 基本的な行間

// サンセリフ・ゴシック体
$sanserif: YakuHanJPs, "Noto Sans JP", 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
// セリフ・明朝体
$serif: "游明朝", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
$Lato: 'Lato', sans-serif;

$helvetica : 'Helvetica Neue', 'Arial', sans-serif;

//CDN
// $helvetica : 'Helvetica Neue', sans-serif; //(https://www.cdnfonts.com/helvetica-neue-9.font)

//----------------------------
//    コンテンツ
//---------------------------
$content-bg       : $white;
$font-size        : 190%;//全体のフォントサイズ
$full-size   : 100%;//基本のコンテンツ余白

//-----------------------------
//    装飾の定義
//-----------------------------
$border-radius   : 3px;//全体の角丸サイズ
$transition: .2s ease;

//----------------------------------------------------------------
//    ボタンの定義
//----------------------------------------------------------------
$btn-width       : 350px;//PCサイトアクションボタンの大きさ
$border-btn          : none;//テーブルのボーダーカラー
$border-btn-width   : 0px;//テーブルのボーダーサイズ
$border-txt-color   : $white;//テーブルのボーダーサイズ
