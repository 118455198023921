//アニメーション

//ラインドロップアニメーション
@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

.bgAnime-lines {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  // background-color: red;
  max-width: $contentWidth;
  width: 100%;
  height: 100vh;

  //bodyにつける
  &__wrap {
    position: relative;
    overflow: hidden;
  }

  //内部パーツ
  .bgAnime {
    &__line {
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      background: rgba(#707070, .08);
      // background-color: red;
      overflow: hidden;
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 15vh;
        width: 100%;
        top: -50%;
        left: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, $keyColor 75%, $keyColor 100%);
        animation: drop 7s 0s infinite;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
      }

      //個別ライン
      &:nth-child(1) {
        left: 0;
        @include mq-max(content) {
          display: none;
        }
        &::after {
          animation-delay: 0s;
        }
      }
      &:nth-child(2) {
        left: calc((#{$contentWidth} / 5) * 1);
        @include mq-max(content) {
          left: 20%;
        }
        @include mq-max(sm) {
          left: $contentPadding;
        }
        @include mq-max(xs) {
          left: $contentPadding/2;
        }
        &::after {
          animation-delay: .5s;
        }
      }
      &:nth-child(3) {
        left: calc((#{$contentWidth} / 5) * 2);
        @include mq-max(content) {
          left: 40%;
        }
        @include mq-max(sm) {
          left: 50%
        }
        &::after {
          animation-delay: 1s;
        }
      }
      &:nth-child(4) {
        left: calc((#{$contentWidth} / 5) * 3);
        @include mq-max(content) {
          left: 60%;
        }
        @include mq-max(sm) {
          left: auto;
          right: $contentPadding;
        }
        @include mq-max(xs) {
          left: auto;
          right: $contentPadding/2;
        }
        &::after {
          animation-delay: 1.5s;
        }
      }
      &:nth-child(5) {
        left: calc((#{$contentWidth} / 5) * 4);
        @include mq-max(content) {
          left: 80%;
        }
        @include mq-max(sm) {
          display: none;
        }
        &::after {
          animation-delay: 2s;
        }
      }
      &:nth-child(6) {
        left: auto;
        right: 0;
        @include mq-max(content) {
          display: none;
        }
        &::after {
          animation-delay: 2.5s;
        }
      }
    }
  }
}