//サイドGナビ
.l-navigation {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  @include mq-max($changePoint) {
    background-color: $white;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 95px);
    height: 100vh;
    margin-left: auto;
    transform: translateX(100%);
    transition: all $transition;
    -webkit-transition: -webkit-transform 0.2s;
    // visibility: hidden;
    pointer-events: none;
    overflow: hidden;
    z-index: 110;
    display: block;
    padding: 97px 0 130px;
    overflow: scroll;
    @media (orientation: landscape) {
      padding-bottom: 100px;
    }
    @include mq-max(xs) {
      width: calc(100% - 45px);
    }
  }
  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include mq-max(content) {
      padding: 0;
    }
    @include mq-max($changePoint) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: auto;
    }
  }
  &__item {
    height: 100%;
    @include mq-max($changePoint) {
      width: 100%;
      height: auto;
      margin-bottom: 0px;
      border-bottom: 1px solid $bdrColor;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      height: 100%;
      display: flex;
      align-items: center;
      color: $textColor;
      transition: all $transition !important;
      font-size: 14px;
      @include mq-max(bp1100) {
        font-size: 13px;
      }
      @include mq-max($changePoint) {
        font-size: 19px;
        height: auto;
        justify-content: center;
        position: relative;
      }
      &::after {
        @include mq-max($changePoint) {
          content: '';
          display: inline-block;
          width: 9px;
          height: 16px;
          background: url('/inc/image/common/ico/ico_arrow-right.svg')no-repeat center / contain;
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
          transition: all $transition;
        }
      }
    }
    &>a {
      font-weight: 600;
      padding: 10px 16px;
      @include mq-max(bp1350) {
        padding: 10px 10px;
      }
      @include mq-max(content) {
        padding: 10px 8px;
      }
      @include mq-max($changePoint) {
        padding: 16px 40px;
      }
      &:hover {
        color: $keyColor;
      }
      //現在地
      &.current {
        position: relative;
        color: $keyColor;
        &:before {
          content: '';
          display: inline-block;
          background-color: $keyColor;
          width: 18px;
          height: 3px;
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          @include mq-max($changePoint) {
            width: 3px;
            height: 23px;
            bottom: 50%;
            left: 25px;
            transform: translateY(50%);
          }
        }
      }
    }

    //ドロップダウン
    &.has-dropdown {
      position: relative;
      &>a {
        //展開時
        &.is-dropdownActive {
          &::after {
            @include mq-max($changePoint) {
              transform: translateY(-50%) rotate(90deg);
            }
          }
        }
      }
    }

    //ホーム
    &--home {
      display: none;
      @include mq-max($changePoint) {
        display: block;
      }
    }

    //お問い合わせ
    &--contact {
      max-width: 146px;
      width: 100%;
      height: auto;
      margin-left: 25px;
      @include mq-max(bp1350) {
        margin-left: 10px;
      }
      @include mq-max(bp1100) {
        max-width: 120px;
      }
      @include mq-max($changePoint) {
        max-width: 100%;
        margin-left: 0;
        margin-top: 50px;
        margin-bottom: 45px;
        padding: 0 $contentPadding;
        border-bottom: none;
      }
      @include mq-max(xs) {
        padding: 0 $contentPadding/2;
      }
      &>a {
        display: block;
        background-color: $keyColor;
        color: $white;
        border-radius: 4px;
        padding: 17px 10px;
        text-align: center;
        @include mq-max($changePoint) {
          font-size: 16px;
          padding: 21px 45px;
          background-color: $black-2;
          position: relative;
        }
        &::after {
          @include mq-max($changePoint) {
            content: '';
            display: inline-block;
            width: 8px;
            height: 14px;
            background: url('/inc/image/common/ico/ico_arrow-right-white.svg')no-repeat center / contain;
            position: absolute;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
          }
        }
        &:hover {
          color: $white;
          background-color: $keyColor-dark;
        }
      }

      .l-navInfo {
        display: none;
        @include mq-max($changePoint) {
          display: block;
        }
      }
      .l-navContact {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        @include mq-max(xs) {
          flex-direction: column;
        }
        &__tel {
          margin-right: 17px;
          font-size: 16px;
          @include mq-max(xs) {
            margin-right: 0;
          }
          .c-tel {
            display: inline-block;
            &::after {
              content: none;
            }
          }
        }
        &__term {
          font-size: 12px;
        }
      }
    }

    //言語
    &--lang {
      margin-left: 25px;
      width: 90px;
      @include mq-max(xlg) {
        width: 65px;
        margin-left: 15px;
      }
      @include mq-max($changePoint) {
        width: 100%;
        // height: 90px;
        margin-left: 0;
        border: none;
      }
      &>a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $ultraGray;
        width: 100%;
        height: 100%;
        padding: 5px;
        transition: all $transition;
        @include mq-max($changePoint) {
          height: 90px;
        }
        &:hover {
          background-color: $lightGray;
        }
        &::after {
          @include mq-max($changePoint) {
            content: none;
          }
        }
      }

      .lang {
        display: inline-block;
        color: $black-2;
        font-family: $Lato;
        font-weight: 600;
        font-size: 13px;
        @include mq-max($changePoint) {
          font-size: 16px;
        }
        &::after {
          content: '/';
          display: inline-block;
          margin-left: 3px;
          margin-right: 0px;
        }
        &:last-child {
          &::after {
            content: none;
          }
        }

        &__wrap {
          @include mq-max($changePoint) {
            display: flex;
            align-items: center;
          }
          &::before {
            @include fa('f0ac');
            color: $black-2;
            font-size: 24px;
            display: block;
            line-height: 1;
            text-align: center;
            margin-bottom: 3px;
            @include mq-max($changePoint) {
              margin-bottom: 0;
              margin-right: 14.5px;
              display: inline-block;
            }
          }
        }

        //現在の言語ハイライト
        &--current {
          color: $keyColor;
        }
      }
    }
  }


  //SP展開時のロゴ
  .l-navLogo {
    display: none;
    @include mq-max($changePoint) {
      display: block;
      max-width: 115px;
      margin: 60px auto 0;
    }
    img {
      width: 100%;
      display: block;
      line-height: 1;
    }
  }


  //展開時
  &.is-navOpen {
    @include mq-max($changePoint) {
      transform: translateX(0%);
      // visibility: visible;
      pointer-events: auto;
    }
  }
}

//ナビアニメーション



//ドロップダウン
.l-dropDown {
  position: absolute;
  left: 0;
  transform: translateY(-30px);
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease;
  pointer-events: none;
  width: 180px;//要調節
  @include mq-max($changePoint) {
    display: none;
    transition: opacity .5s ease;
    transform: translateY(0);
    width: 100%;
    position: relative;
    // padding-left: 15px;
  }
  &>li {
    border-bottom: 1px solid $bdrColor;
    @include mq-max($changePoint) {
      overflow: hidden;
      transition: all .5s ease;
    }
    &:first-child {
      border-top: 1px solid $bdrColor;
    }
    &:last-child {
      @include mq-max($changePoint) {
        border-bottom: none;
      }
    }
    &>a {
      background-color: $white;
      display: block;
      font-size: 15px;
      font-weight: 600;
      padding: 10px 11px;
      @include mq-max($changePoint) {
        padding: 10px 40px;
        text-align: center;
      }
      &:hover {
        background-color: $keyColor;
        color: $white;
        @include mq-max($changePoint) {
          color: $textColor;
          background-color: $white;
        }
      }
    }
  }

  //展開時
  &.isDropdownOpen {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;

    &>li {
      @include mq-max($changePoint) {
        // height: 32px;
      }
    }
  }
}
