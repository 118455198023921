.c-list {
  list-style-type: none;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 30px;
  line-height: 2;
  &:last-child {
    margin-bottom: 0;
  }

  //丸付き
  &--disc {
    & > .c-list__item {
      position: relative;
      &:before {
        content: '・';
        display: inline-block;
        color: $keyColor;
        position: absolute;
        top: 0;
        left: -1em;
        font-size: 1.5em;
        line-height: 1.5;
        @media all and (-ms-high-contrast: none) {
          top: -0.1em;
        }
      }
    }
  }

  //四角付き
  &--rect {
    padding-left: 15px;
    & > .c-list__item {
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: $keyColor;
        position: absolute;
        top: .6em;
        left: -15px;
        font-size: 1.5em;
        line-height: 1.5;
      }
    }
    &-lightGray {
      & > .c-list__item {
        &:before {
          background-color: $lightGray;
        }
      }
    }
  }

  //カウント
  &--count {
    counter-reset: count;
    & > .c-list__item {
      position: relative;
      &:before{
        counter-increment: count;
        content:counter(count);
        position: absolute;
        left: -1.1em;
        color: $keyColor;
      }
    }
    &--brackets{
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        &:before{
          counter-increment: count;
          content:"("counter(count)")";
          position: absolute;
          left: -1.5em;
          color: $keyColor;
        }
      }
    }
  }

  //注釈
  &--comment {
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    & > .c-list__item {
      position: relative;
      &:before{
        content: '※';
        display: inline-block;
        position: absolute;
        left: -1.5em;
        @media all and (-ms-high-contrast: none) {
          top: 0.1em;
        }
      }
    }
    //番号付き注釈
    &-num {
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        padding-left: 0.5em;
        &:before {
          counter-increment: count;
          content: '※'counter(count);
          position: absolute;
          left: -1.5em;
          @media all and (-ms-high-contrast: none) {
            top: 0;
          }
        }
      }
    }
  }

  //英語：注釈（アスタリスク）
  &--asterisk {
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    & > .c-list__item {
      position: relative;
      &:before{
        content: '*';
        display: inline-block;
        position: absolute;
        left: -0.8em;
        @media all and (-ms-high-contrast: none) {
          top: 0.1em;
        }
      }
    }
  }
}


//対応原料、対応製品
.c-material {
  &__item {
    display: flex;
    margin-bottom: 8px;
    @include mq-max(xs) {
      flex-direction: column;
      margin-bottom: 14px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    font-size: 14px;
    font-weight: 600;
    flex-shrink: 0;
    @include mq-max(xs) {
      margin-bottom: 5px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -6px;
    margin-bottom: -6px;
    position: relative;
    top: -5px;
    @include mq-max(xs) {
      position: static;
    }
    &>li {
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
  &__label {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    color: $textColor !important;
    padding: 4px 14px;
    background-color: $white;
    border: 1px solid $bdrColor;
    border-radius: 6px;
    transition: all $transition;
  }
}
//aタグの場合ホバー効果
a.c-material__label {
  &:hover {
    background-color: $ultraGray;
  }
}