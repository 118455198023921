//カード

//通常カード
.c-card {
  box-shadow: 0px 0px 30px rgba(0,0,0,.1);

  //レイアウト
  &__list {
    margin: -50px 0;
  }
  &__item {
    padding: 50px 0;
    &>a {
      display: block;
      max-width: 443px;
      margin: 0 auto;
      @include mq-max(sm) {
        padding-right: $contentPadding;
        padding-left: $contentPadding;
      }
      &:hover {
        .c-card__thumb img {
          transform: scale(1.1);
        }
      }
    }
  }

  //構成パーツ
  &__thumb {
    position: relative;
    overflow: hidden;
    &-name {
      display: inline-block;
      background-color: $black-2;
      padding: 9.5px 22px;
      color: $white;
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    img {
      display: block;
      line-height: 1;
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: center;
      font-family: "object-fit: cover; object-position: center;";
      transition: all $transition;
      @include mq-max(xs) {
        height: 200px;
      }
    }
  }
  &__body {
    background-color: $white;
    padding: 38.5px 26px 21px 28px;
    @include mq-max(sm) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include mq-max(xs) {
      padding-top: 25px;
    }
  }
  &__title {
    font-size: 19px;
    line-height: (28 / 19);
    margin-bottom: 40px;
    padding-left: 82px;
    position: relative;
    @include mq-max(sm) {
      padding-left: 70px;
    }
    @include mq-max(xs) {
      font-size: 17px;
    }
  }
  &__count {
    display: inline-block;
    font-size: 31px;
    font-weight: 300;
    letter-spacing: 0.02em;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__tags {
    display: flex;
    justify-content: flex-end;
    &-item {
      display: inline-block;
      font-size: 13px;
      color: $textColor;
      padding: 4px 12.5px;
      border: 1px solid $bdrColor;
      border-radius: 6px;
      margin-right: 6px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  //一覧ページ用カード
  &--works {
    transition: all $transition;
    box-shadow: 0px 0px 10px rgba(0,0,0,.1);
    height: 100%;
    &__list {
      padding-top: 50px;
      margin-bottom: -80px;
    }
    &__item {
      padding-top: 0;
      @include mq-max(sm) {
        padding-bottom: 10px;
      }
      & > a {
        height: 100%;
        @include mq-max(sm) {
          padding-right: 0;
          padding-left: 0;
        }
        &:hover {
          .c-card--works {
            transform: scale(1.05);
            box-shadow: 0px 0px 30px rgba(0,0,0,.1);
            img {
              transform: scale(1.05);
            }
          }
          .c-card__body {
            background-color: $white;
          }
        }
      }
    }
    .c-card {
      &__body {
        background-color: $grayishWhite;
        padding-top: 30px;
        transition: all $transition;
      }
      &__title {
        padding-left: 0;
        margin-bottom: 20px;
      }
      &__thumb {
        overflow: hidden;
        img {
          height: 250px;
          transition: all $transition;
          @include mq-max(sm) {
            height: 230px;
          }
        }
      }
      &__tags {
        display: block;
        &-item {
          margin-bottom: 5px;
          span {
            font-weight: 600;
            margin-left: 1em;
          }
        }
      }
    }
  }
}


//チェックカード
.c-checkCard {
  display: flex;
  box-shadow: (0px 0px 30px rgba(0,0,0,.1));
  @include mq-max(iPadPro-v) {
    flex-direction: column;
  }

  &__item {
    position: relative;
    border-right: 1px solid $bdrColor;
    @include mq-max(iPadPro-v) {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $bdrColor;
    }
    &:last-child {
      border-right: none;
      @include mq-max(iPadPro-v) {
        border-bottom: none;
      }
    }
    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(3.0273399353027344px);
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__inner {
    position: relative;
    z-index: 1;
    padding: 77px 26px 45px;
    @include mq-max(iPadPro-v) {
      padding: 40px 20px 40px 26px;
      display: flex;
      align-items: center;
    }
    //三角装飾
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 71px 71px 0 0;
      border-color: $lightGray transparent transparent transparent;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    //チェックマーク
    &:after {
      content: '';
      width: 23px;
      height: 23px;
      display: inline-block;
      background: url('/inc/image/common/ico/ico_circle-check-white.svg')no-repeat center / contain;
      position: absolute;
      top: 12.5px;
      left: 8.6px;
      z-index: 1;
    }
  }
  &__title {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    @include mq-max(iPadPro-v) {
      width: 207px;
      flex-shrink: 0;
      margin-bottom: 0;
    }
    @include mq-max(bp480) {
      width: 120px;
      font-size: 20px;
    }
  }
  &__txt {
    font-size: 14px;
    letter-spacing: 0.03em;
    line-height: (26 / 14);
    @include mq-max(iPadPro-v) {
      flex-grow: 1;
    }
  }
}


//素材カード
.c-materialCard {
  //レイアウト
  // &__wrap {}
  &__item {
    padding: 10px 0;
    &>a {
      max-width: 244px;
      width: 100%;
      margin: 0 auto;
      display: block;
      &:hover {
        .c-materialCard {
          &__inner {
            background-color: rgba($white, 1);
            box-shadow: 0px 0px 20px rgba(0,0,0,.05);
          }
          &__thumb {
            width: 160px;
            height: 160px;
            top: 25px;
          }
          &__txt {
            opacity: 1;
          }
        }
      }
    }
  }

  //構成パーツ
  &__inner {
    // max-width: 244px;
    // width: 100%;
    margin: 0 auto;
    padding: 200px 23px 18px 23px;
    background-color: rgba($red, 0);
    transition: all $transition;
    position: relative;
  }
  &__thumb {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 30px;
    transition: all $transition;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    &--noPoa {
      position: static;
      transform: none;
      margin-bottom: 10px;
    }
  }
  // &__body {}
  &__name {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }
  &__txt {
    font-size: 13px;
    font-weight: 400;
    color: #6c6c6c;
    line-height: (21 / 13);
    opacity: 0;
    transition: all $transition;
  }


  //アンカーリンク仕様
  &--anchor {
    &:hover {
      .c-materialCard {
        &__inner {
          &::after {
            bottom: 35px;
            @include mq-max(sm) {
              bottom: 20px;
            }
          }
        }
      }
    }
    .c-materialCard {
      &__thumb {
        @include mq-max(md) {
          width: 130px !important;
          height: 130px !important;
          top: 40px !important;
        }
        @include mq-max(sm) {
          width: 100px !important;
          height: 100px !important;
          top: 15px !important;
        }
      }
      &__inner {
        padding-bottom: 65px;
        @include mq-max(sm) {
          padding-bottom: 45px;
          padding-top: 135px;
        }
        &::after {
          @include fa('f078');
          line-height: 1;
          position: absolute;
          bottom: 40px;
          left: 50%;
          transform: translateX(-50%);
          color: $textColor;
          transition: all $transition;
          @include mq-max(sm) {
            bottom: 25px;
          }
        }
      }
      &__name {
        margin-bottom: 0;
        @include mq-max(sm) {
          font-size: 14px;
        }
      }
    }
  }


  //標本
  &__box {
    &-item {
      position: relative;
      width: 100%;
      background: $white;
      box-shadow: 0px 0px 40px rgba(0,0,0,.1);
      margin-bottom: 54px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-inner {
      padding: 31px 40px;
      @include mq-max(xs) {
        padding: 25px 15px;
      }
    }
    &-name {
      font-size: 16px;
      text-align: center;
      margin-bottom: 20px;
    }
    &-txt {
      font-size: 14px;
      text-align: center;
      margin-bottom: 0;
    }

    &-head {
      padding: 33px 15px 30px;
      @include mq-max(xs) {
        padding: 25px 15px;
      }
    }
    &-body {
      width: 100%;
      background-color: $ultraGray;
      padding: 35px 45px;
      display: flex;
      position: relative;
      @include mq-max(xs) {
        padding: 20px 15px;
      }
      &::before {
        content: '';
        width: 1px;
        height: 30px;
        background-color: $lightGray;
        display: block;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
      }

      //真ん中寄せ
      &--center {
        justify-content: center;
        .c-materialCard__box-main {
          flex-shrink: 1;
        }
        .c-materialCard__box-sub {
          justify-content: flex-start;
          flex-grow: 0;
        }
        .c-materialCard__box-flex {
          width: auto;
        }
      }

      //パーツ調整
      .c-materialCard {
        &__thumb {
          @include mq-max(xs) {
            width: 90px;
            height: 90px;
          }
        }
        &__box {
          &-txt {
            @include mq-max(xs) {
              font-size: 12px;
            }
          }
        }
      }
    }
    &-main {
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid $bdrColor;
      flex-shrink: 0;
      @include mq-max(xs) {
        padding-right: 10px;
        margin-right: 10px;
      }
    }
    &-sub {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 -5px -10px;
      @include mq-max(md) {
        justify-content: flex-start;
      }
    }
    &-flex {
      padding: 0 5px 10px;
      flex-shrink: 0;
      @include mq-max(md) {
        width: 25%;
      }
      @include mq-max(bp900) {
        width: calc(100% / 3);
      }
      @include mq-max(bp700) {
        width: 50%;
      }
    }
  }

  //比較カード(gridlexと併用)
  &__compare {
    &-card {
      position: relative;
      width: 100%;
      background: $white;
      box-shadow: 0px 0px 40px rgba(0,0,0,.1);
      @include mq-max(md) {
        max-width: 700px;
        margin: 0 auto;
      }
      //パーツ調整
      .c-materialCard__box-inner {
        @include mq-max(md) {
          // padding-left: 15px;
          // padding-right: 15px;
        }
      }
    }
    &-pair {
      display: flex;
      justify-content: space-between;
      @include mq-max(md) {
        max-width: 385px;
        margin: 0 auto;
      }
      @include mq-max(bp480) {
        max-width: 300px;
      }
    }
    &-item {
      &--before {
        position: relative;
        top: 13px;
        @include mq-max(bp480) {
          top: 18px;
        }
        .c-materialCard__thumb {
          margin-bottom: 23px;
          @include mq-max(bp480) {
            width: 90px;
            height: 90px;
          }
        }
        &::after {
          content: '';
          width: 65px;
          height: 1px;
          background-color: $lightGray;
          display: block;
          position: absolute;
          right: -80px;
          top: 70px;
          @include mq-max(bp480) {
            top: 45px;
          }
        }

        //英語調整
        .eng & {
          &::after {
            @include mq-max(bp480) {
              width: 50px;
              right: -48px;
            }
          }
        }
      }
      &--after {
        .c-materialCard__thumb {
          width: 156px;
          height: 156px;
          @include mq-max(bp480) {
            width: 120px;
            height: 120px;
          }
        }
      }
    }
  }
}

//活用分野カード
.c-fieldCard {
  display: flex;
  position: relative;
  background-color: $white;
  @include mq-max(sm) {
    flex-direction: column-reverse;
  }
  &__wrapper {
    background-color: $ultraGray;
    padding-bottom: 60px;
    &:first-child {
      background-color: none;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 41.5%, #{$ultraGray} 41.5%, #{$ultraGray} 100%);
    }
    @include mq-max(content) {
      padding: 0 $contentPadding 60px;
    }
    @include mq-max(sm) {
      padding: 0 $contentPadding/2 60px;
    }
  }
  &__title {
    display: inline-block;
    background-color: $black-2;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px 18px;
  }
  &__infoArea {
    padding: 76px 40px 40px;
    max-width: 690px;
    flex-shrink: 1;
    @include mq-max(sm) {
      padding-top: 70px;
    }
  }

  &__copy {
    font-size: 20px;
    margin-bottom: 10px;
    @include mq-max(sm) {
      padding-left: 100px;
      font-size: 18px;
      margin-bottom: 40px;
    }
    @include mq-max(xs) {
      font-size: 16px;
      padding-left: 90px;
      margin-bottom: 28px;
    }
    //英語：字間を詰める
    .eng & {
      letter-spacing: 0;
    }
  }

  &__text {
    font-size: 15px;

  }

  &__imgArea {
    flex-shrink: 0;
    @include mq-max(sm) {
      max-width: 80px;
      position: absolute;
      top: 72px;
      left: 43px;
    }
    @include mq-max(xs) {
      max-width: 70px;
    }

    img {
      object-fit: cover;
      font-family: "object-fit: cover;";
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

}

//導入先コメントカード
.c-comentCard {
  background-color: $ultraGray;
  padding: 40px;
  @include mq-max(sm) {
    padding: 40px 15px;
  }
  &__wrapper {
    // background:  linear-gradient(180deg, #{$grayishWhite} 0%, #{$grayishWhite} 59%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0) 100%), url('/inc/image/common/bg/bg_dots.png');
  }
  &__title {
    font-size: 20px;
    @include mq-max(xs) {
      text-align: center;
    }
  }
  &__image {
    max-width: 170px;
    margin-right: 20px;
    flex-shrink: 0;
    @include mq-max(xs) {
      margin: 0 auto 10px;
    }
  }
  &__text {
    margin-left: 210px;
    margin-top: -47px;
    @include mq-max(sm) {
      margin-top: 0;
      margin-left: 0;
    }

  }
  &__Grid {
    display: flex;
    @include mq-max(sm) {
      margin-bottom: 20px;
    }
    @include mq-max(xs) {
      flex-direction: column;
    }
  }
  &__info {
    max-width: 670px;
    margin-left: 20px;
    @include mq-max(xs) {
      margin-left: 0;
    }
    &__title {
      margin-bottom: 20px;
    }
    &__name {
      // margin-bottom: 45px;
    }
  }
}