//スライダー共通パーツ

.c-slider {
  &__wrap {
    //slick改造
    .cloneCurrent,
    .slick-current,
    .slick-center {
      .c-card {
        &__item {
          &>a {
            transition: all 1s ease !important;
            transform: scale(1.1);
            @include mq-max(sm) {
              transform: none;
            }
          }
        }
      }
    }
    .slick-dots {
      bottom: -20px;
      @include mq-max(sm) {
        bottom: 0;
      }
    }

    //パーツ調整
    .c-slider {
      &__control {
        &-item {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
          z-index: 3;
          &--prev {
            left: 15px;
            @include mq-max(sm) {
              left: 40px;
            }
            @include mq-max(xs) {
              left: 10px;
            }
          }
          &--next {
            right: 15px;
            @include mq-max(sm) {
              right: 40px;
            }
            @include mq-max(xs) {
              right: 10px;
            }
          }
        }
      }
    }
  }

  //コントローラー
  &__control {
    display: flex;
    &-item {
      margin-right: 11px;
      &:last-child {
        margin-right: 0;
      }

      // 前後
      &--prev {}
      &--next {}
    }

    &-btn {
      width: 48px;
      height: 48px;
      background-color: $ultraGray;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: $black-2;
      transition: all $transition;
      &:hover {
        background-color: $keyColor;
        color: $white;
      }
    }
  }

}